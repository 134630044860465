import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import twitter from './twitter.svg';
import linkedin from './linkedin.svg';
import github from './github.svg';

class App extends Component {
  render() {
    return (
      <Router>

        <Route exact path="/" component={Home} />
        {/* <Route path="/photography" component={Photography} /> */}


      </Router>

    );
  }
}


// class Photography extends Component {
//   render() {
//     return (
//       <p>Photography</p>
//     );
//   }
// }

class Home extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1 className='App-headline'>
            Lasse Kabell
          </h1>
          <a
            className="App-email"
            href="mailto:lasse@lassekabell.dk"
            target="_blank"
            rel="noopener noreferrer"
          >
            lasse@lassekabell.dk
          </a>
          <div className='link-container'>
            <a
              className="App-link"
              href="https://www.linkedin.com/in/lassekabell/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icon" src={linkedin} alt='LinkedIn' />
            </a>
            <a
              className="App-link"
              href="https://github.com/lkabell"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icon" src={github} alt='GitHub' />
            </a>
            <a
              className="App-link"
              href="https://twitter.com/LasseK"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icon" src={twitter} alt='Twitter' />
            </a>
          </div>
        </header>
        <article className='App-article'>
          I build  software.<br /><br />
          I’m a Copenhagen-based M.Sc. in IT Engineering who specializes in building web-based services. Currently, I work as Software Engineer at <a href="https://pnorental.com/" target="_blank" rel="noopener noreferrer">PNO Ventures</a>.<br /><br />
          Want to get in touch? Don’t hesitate to send me an <a className="" href="mailto:lasse@lassekabell.dk" rel="noopener noreferrer" >
            email
          </a>.
        </article>
      </div>
    );
  }
}

export default App;
